import Layout from '../../hocs/Layout';
import { Link } from 'react-router-dom';
import { WhatsAppButton } from '../../components/aditional/WhatsApp';
import { SwiperBanner } from '../../components/home/SwiperBanner';
import '../../styles/static-pages.css'
import { connect } from 'react-redux'
import { get_highlighted_products } from '../../redux/actions/products';
import { get_items, get_total, get_item_total, add_item } from '../../redux/actions/cart';
import { useEffect, useState } from 'react';
import { HomeCard } from '../../components/cards/HomeCard';


const Home = ({
	get_highlighted_products,
	popular_products,
	top_selling,
	new_arrivals,
	get_items,
	get_total,
	get_item_total,
	add_item,
}) => {

	const [mostPopulars, setMostPopulars] = useState(false);
	const [topSelling, setTopSelling] = useState(false);

	useEffect(() => {
		window.scrollTo(0,0);
		get_highlighted_products();
	}, []);

	const addToCart = async (product) => {
	    if (product && product !== null && product !== undefined && product.quantity > 0) {
	        await add_item(product);
	        await get_items();
	        await get_total();
	        await get_item_total();
	    }
	}

	const showProducts = () => {
		let display = []
		let items = [];

        if (mostPopulars) {
            items = Array.isArray(popular_products) ? popular_products : [];
        }
        else if (topSelling) {
            items = Array.isArray(top_selling) ? top_selling : [];
        }
        else {
            items = new_arrivals;
        }

		if (items && items.length > 0) {
            items.forEach((product, index) => {
				display.push(
					<div key={index} className='product-card-wrapper'>
						<HomeCard product={product} addToCart={addToCart} />
					</div>
				);
			})
		}
		return display;
	}

	const handleNewArrival = () => {
		setMostPopulars(false)
		setTopSelling(false)
	}

	const handleMostPopular = () => {
		setMostPopulars(true)
		setTopSelling(false)
	}

	const handleTopSelling = () => {
		setMostPopulars(false)
		setTopSelling(true)
	}
	
	return (
		<Layout>
			<div className='container-banner-all home'>
				<Link to='/shop' className='button-main-banner' id='banner-button' style={{textDecoration: 'none'}} >Ver Tienda</Link>
			</div>
			<div className='container-toggle-products'>
				<h2>Nuestros productos</h2>
				<div className='container-toggle-home'>
					<div className='contain-toggle-home'>
						<button className={`toggle-products-home ${(!mostPopulars && !topSelling) ? 'toggle-active' : ''}`} onClick={handleNewArrival}>Recientes</button>
						<button className={`toggle-products-home ${(mostPopulars) ? 'toggle-active' : ''}`} onClick={handleMostPopular}>Populares</button>
						<button className={`toggle-products-home ${(topSelling) ? 'toggle-active' : ''}`} onClick={handleTopSelling}>Más Vendidos</button>
					</div>
				</div>
				<div className='contain-products-toggle'>
					{showProducts()}
				</div>
			</div>
			<div className='container-mensual-promo'>
				<div className='contain-mensual-promo'>
					<h3>Verano de descuentos</h3>
					<p>Sumergete en nuestras promociones y descuentos exclusivos en este Enero</p>
					<Link to='/shop' className='link-to-shop' style={{textDecoration: 'none'}}>Conócelas</Link>
				</div>
			</div>
    		<div className='container-home-grid'>
    			<div className='contain-home-grid-h1'>
    				<div className='home-grid-content'>
    					<h3>Nuestra Historia</h3>
    					<p>Conoce nuestra historia, nuestra misión y como trabajamos</p>
    					<Link to='/about' className='links-grid-home' style={{textDecoration: 'none'}}>Click Aquí</Link>
    				</div>
     		 	</div>
     		 	<div className='contain-home-grid-h2'>
     		 		<div className='home-grid-content'>
    					<h3>Alianzas de boda</h3>
    					<p>Elige tus alianzas y hazlas a la medida</p>
    					<Link to='/dates' className='links-grid-home' style={{textDecoration: 'none'}}>Click Aquí</Link>
    				</div>
     		 	</div>
     		 	<div className='contain-home-grid-v'>
     		 		<div className='home-grid-content-v'>
    					<h3>Joyeria Personalizada</h3>
    					<p>Contamos con joyeria personalizable, haznos tu consulta</p>
    					<Link to='https://wa.me/+543574454270?text=Buenos%20días,%20La%20Joyita,%20quiero%20conocer%20la%20joyeria%20personalizada.' className='links-grid-home' style={{textDecoration: 'none'}}>Click Aquí</Link>
    				</div>
     		 	</div>
    		</div>
			<div className='container-home-gold'>
				<div className='contain-home-gold'>
					<h3>Descubra la Distinción de nuestros productos de Oro</h3>
					<p>Coordine una cita con nosotros, ya sea por videollamada o en alguna de nuestras sucursales, y descubra la elegancia y la exclusividad de nuestros productos de oro finamente trabajados</p>
					<Link to='/dates' className='link-to-cite' style={{textDecoration: 'none'}}>Solicite su cita</Link>
				</div>
			</div>
			<div className='container-caracteristics'>
				<div className='caracteristics-item'>
					<div className='caracteristics-item-img'>
						<img src={require(`../../images/entrega.png`)} alt='entrega' />
					</div>
					<div className='caracteristics-item-text'>
						<h4>ENVÍOS</h4>
						<p>Envíos a domicilio en todo el país. A través de diferentes empresas de envíos</p>
					</div>
				</div>
				<div className='caracteristics-item'>
					<div className='caracteristics-item-img'>
						<img src={require(`../../images/billetera.png`)} alt='billetera' />
					</div>
					<div className='caracteristics-item-text'>
						<h4>MEDIOS DE PAGO</h4>
						<p>Todas las tarjetas de crédito, débito, transferencias y efectivo en puntos de pago</p>
					</div>
				</div>
				<div className='caracteristics-item'>
					<div className='caracteristics-item-img'>
						<img src={require(`../../images/descuentos.png`)} alt='descuentos' />
					</div>
					<div className='caracteristics-item-text'>
						<h4>PROMOCIONES</h4>
						<p>Descuentos y promociones disponibles en efectivo, tarjetas o transferencia</p>
					</div>
				</div>
			</div>
			<div className='container-home-swiper'>
				<SwiperBanner />
			</div>
			<div className='container-home-presents'>
				<div className='contain-home-presents'>
					<h3>Descubre los Regalos Perfectos con Nosotros</h3>
					<p>Encuentra joyería personalizada, relojes y otros accesorios exclusivos para dar el regalo ideal</p>
				</div>
				<div className='container-presents-img'>
					<img src={require(`../../images/presents.jpg`)} alt='presents' />
				</div>
			</div>
			<WhatsAppButton />
		</Layout>
	);
};

const mapStateToProps = state => ({
	popular_products: state.Products.popular_products,
	top_selling: state.Products.top_selling,
	new_arrivals: state.Products.new_arrivals
})

export default connect(mapStateToProps, {
	get_highlighted_products,
	get_items,
	get_total,
	get_item_total,
	add_item,
}) (Home)